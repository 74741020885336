import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/SAR_Cover.jpg';
import metaImage from './assets/meta.jpg';

const SarPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="data-type-sar"
      helmet={{
        title: 'TileDB -  Explore SAR AOIs and conduct time-series analysis',
        description:
          'Enjoy faster time-to-insight and optimize SAR analysis workflows using native support in TileDB for time-series cubes for change detection and other algorithms.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="SAR imaging"
      title="Explore SAR images and conduct time-series analyses directly in the cloud"
      heroImage={BackgroundImage}
      icon={dataTypes.sar.icon}
      overview={
        <>
          <p>
            Synthetic aperture radar (SAR) images are increasingly popular in earth observation, but the traditional data formats used to
            distribute them, like Cloud Optimized GeoTIFFs (COGs), make it difficult to manage large collections and conduct time-series
            analysis.
          </p>
          <p>
            Enjoy faster time-to-insight thanks to TileDB’s analysis-ready data format, for fast slicing on cloud object storage.
            Time-series support with TileDB multi-dimensional arrays lets you customize spatio-temporal cubes, with lossless compression
            options that optimize your analysis workflows. Expand your research and run advanced change detection analyses on TileDB Cloud,
            with out-of-core compute at petabyte scale.
          </p>
          <p>
            Increase discoverability of SAR data by storing metadata directly with images in TileDB arrays, making them searchable within
            TileDB Cloud. TileDB Cloud provides out-of-the-box data catalog features, and interoperates with the SpatioTemporal Asset
            Catalog (STAC) specification for geospatial dataset discovery.
          </p>
        </>
      }
      relatedItems={[industries.earthObservation, industries.defense, industries.reinsurance, dataTypes.ais]}
    />
  );
};

export default SarPage;
